import { Pipe, PipeTransform } from '@angular/core';
import { StatutFormule } from '../formules.interface';

@Pipe({
  name: 'formuleStatutClass',
  standalone: true,
})
export class FormuleStatutClassPipe implements PipeTransform {
  transform(statutFormule: StatutFormule): string {
    switch (statutFormule) {
      case StatutFormule.AControler:
        return 'capsKo';
      case StatutFormule.ARedditionner:
        return 'capsInfo';
      case StatutFormule.Acompte:
        return 'capsInfo';
      case StatutFormule.AcompteEnCours:
        return 'capsInfo';
      case StatutFormule.EnCours:
        return 'capsWait';
      case StatutFormule.Redditionne:
        return 'capsOk';
      case StatutFormule.Reverse:
        return 'capsOk';
      default:
        return 'capsKo';
    }
  }
}
