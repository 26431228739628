<div class="body-container">
  <div class="content_header">
    @if (this.factureEnCours()) {
      <div class="facture_loader">
        <span data-text="E" data-text-before=" " class="invisible">I</span>
        <span data-text="N" data-text-before="D" class="invisible">I</span>
        <span data-text=" " data-text-before="O" class="invisible">I</span>
        <span data-text="C" data-text-before="C" class="invisible">I</span>
        <span data-text="R" data-text-before="U" class="invisible">I</span>
        <span data-text="E" data-text-before="M" class="invisible">I</span>
        <span data-text="A" data-text-before="E" class="invisible">I</span>
        <span data-text="T" data-text-before="N" class="invisible">I</span>
        <span data-text="I" data-text-before="T" class="invisible">I</span>
        <span data-text="O" data-text-before=" " class="invisible">I</span>
        <span data-text="N" data-text-before=" " class="invisible">I</span>
      </div>
    }
  </div>
  <div class="content_body reddition_body bs_table">
    <app-formules-filtre class="body-sub-container"></app-formules-filtre>
    <app-formules-liste class="body-sub-container"></app-formules-liste>
  </div>
  <div
    class="panel_background"
    [@voirDetail]="voirDetailformule()"
    (click)="fermerPanelDetails()"
    (keypress)="fermerPanelDetails()"
  ></div>
  <div class="panel_formule_container" [@openSlider]="voirDetailformule() ? 'ouvert' : 'fermer'">
    @defer (when panelformule()) {
      @if (panelformule()) {
        <app-panel-formule-detail></app-panel-formule-detail>
      }
      @if (voirPDF()) {
        <div class="visionneuse_document">
          <div class="visionneuse_header">
            <div class="evt_info">
              <p>{{ panelformule()?.idAparte }} {{ panelformule()?.nom }}</p>
              du {{ panelformule()?.date | date }}
            </div>
            <button class="btn-secondaire" mat-flat-button (click)="voirPDF.set(false)">Fermer</button>
            <button class="btn-primaire" mat-flat-button (click)="telechargerDocumentBlob()">
              Télécharger le document
            </button>
          </div>
          <div class="visionneuse_body">
            <app-pdf-viewer [blob]="pdfBlob()!" [filename]="filename()"></app-pdf-viewer>
          </div>
        </div>
      }
    }
  </div>
</div>
