<div class="body-container">
  <div class="content_header"></div>
  <div class="content_body flex-1">
    <form [formGroup]="fichierSapForm" class="fichiersap-form aic jcc flex body-sub-container">
      <app-date-range-picker
        [startDate]="fichierSapForm.controls.filtreDateDebut"
        [endDate]="fichierSapForm.controls.filtreDateFin"
      ></app-date-range-picker>
      <button
        type="submit"
        mat-flat-button
        class="btn-primaire"
        (click)="
          telechargerFichier(fichierSapForm.controls.filtreDateDebut.value, fichierSapForm.controls.filtreDateFin.value)
        "
      >
        Valider
      </button>
    </form>
  </div>
</div>
