import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, switchMap, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Formule } from '../formules.interface';
import { AnnulationAcompteData, InfoAcompteEnCours } from './dialog-acompte-annulation.interface';

@Injectable({
  providedIn: 'root',
})
export class AcompteAnnulationService {
  private httpClient = inject(HttpClient);
  private formule = signal<Formule | undefined>(undefined);

  acompteAAnnuler = signal<AnnulationAcompteData | undefined>(undefined);
  acompteAnnule = signal<boolean>(false);

  private creationAcompte = toSignal(
    toObservable(this.acompteAAnnuler).pipe(
      filter(a => !!a),
      switchMap(a =>
        this.httpClient.post<InfoAcompteEnCours>(`${environment.backendUrl}redditions/acompte/${a.idAcompte}/annuler`, {
          commentaire: a.commentaire,
        }),
      ),
      tap(() => this.acompteAnnule.set(true)),
    ),
  );

  setFormule(formule: Formule) {
    this.formule.set(formule);
  }

  infosAcompteEnCours = toSignal(
    toObservable(this.formule).pipe(
      filter(evt => !!evt),
      switchMap(evt =>
        this.httpClient.get<InfoAcompteEnCours>(
          `${environment.backendUrl}redditions/formules/${evt!.id}/acompte-en-cours`,
        ),
      ),
    ),
  );
}
