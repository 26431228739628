<mat-form-field appearance="outline">
  <mat-label>Saisir les dates</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate [formControl]="startDate" placeholder="Date début" />
    <input matEndDate [formControl]="endDate" placeholder="Date fin" />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix (click)="clearDate()" (keyPress)="clearDate()" [for]="picker">
    @if (startDate.value || endDate.value) {
      <mat-icon matDatepickerToggleIcon>close</mat-icon>
    } @else {
      <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
    }
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
