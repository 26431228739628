import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DownloadService } from '../../../partage/services/download.service';

@Injectable({
  providedIn: 'root',
})
export class FichierSapService {
  private readonly _downloadService = inject(DownloadService);

  telechargerFichier(dateDebut: string, dateFin: string) {
    let queryparams = new HttpParams();
    queryparams = queryparams.appendAll({
      DateDeDebut: moment(dateDebut).format('YYYY-MM-DD'),
    });
    queryparams = queryparams.appendAll({
      DateDeFin: moment(dateFin).format('YYYY-MM-DD'),
    });
    const url = `${environment.backendUrl}facturation/fichier-sap`;
    this._downloadService
      .downloadFile(url, 'export-sap.csv', queryparams)
      .pipe(
        map(fichier => {
          this._downloadService.saveDownloadedFile(fichier);
        }),
      )
      .subscribe();
  }
}
