import { Pipe, PipeTransform } from '@angular/core';
import { StatutFormule } from '../formules.interface';

@Pipe({
  name: 'formuleStatut',
  standalone: true,
})
export class FormuleStatutPipe implements PipeTransform {
  transform(statutFormule: StatutFormule): string {
    switch (statutFormule) {
      case StatutFormule.AControler:
        return 'A contrôler';
      case StatutFormule.ARedditionner:
        return 'A redditionner';
      case StatutFormule.Acompte:
        return 'Acompte';
      case StatutFormule.AcompteEnCours:
        return 'Acompte en cours';
      case StatutFormule.EnCours:
        return 'En cours';
      case StatutFormule.Redditionne:
        return 'Redditionné';
      case StatutFormule.Reverse:
        return 'Reversé';
      default:
        return 'Erreur';
    }
  }
}
