import { Component, Input } from '@angular/core';
import { MatDatepickerModule, MatDateRangeInput } from '@angular/material/datepicker';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-date-range-picker',
  imports: [MatDateRangeInput, MatDatepickerModule, MatIconModule, ReactiveFormsModule, MatInputModule],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
})
export class DateRangePickerComponent {
  @Input({ required: true }) startDate!: FormControl;
  @Input({ required: true }) endDate!: FormControl;

  clearDate(): void {
    this.startDate.reset();
    this.endDate.reset();
  }
}
