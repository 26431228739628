<div class="grid_container">
  <table
    mat-table
    [dataSource]="evenements().items"
    matSort
    (matSortChange)="sortData($event)"
    multiTemplateDataRows
    matSortActive="date"
    matSortDirection="asc"
  >
    <caption></caption>

    <ng-container matColumnDef="nom">
      <th
        class="{{ sort?.active === 'nom' ? '' : 'th_mat_cell_sort' }}"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        <div>Evènement</div>
      </th>
      <td
        mat-cell
        #tdElement
        *matCellDef="let row"
        class="column_content aligne_G"
        matTooltip="{{ row.nom }}"
        [matTooltipDisabled]="!testIsOverflow(tdElement)"
      >
        {{ row.nom }}
      </td>
    </ng-container>
    <ng-container matColumnDef="idAparte">
      <th
        class="{{ sort?.active === 'idAparte' ? '' : 'th_mat_cell_sort' }}"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        <div>Id session</div>
      </th>
      <td mat-cell *matCellDef="let row">{{ row.idAparte }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th
        class="{{ sort?.active === 'date' ? '' : 'th_mat_cell_sort' }}"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        <div>Date & heure</div>
      </th>
      <td
        mat-cell
        #tdElement
        *matCellDef="let row"
        class="column_content"
        matTooltip="{{ row.date | date: 'dd/MM/yyyy HH:mm' }}"
        [matTooltipDisabled]="!testIsOverflow(tdElement)"
      >
        {{ row.date | date: 'dd/MM/yyyy HH:mm' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="nomClient">
      <th
        class="{{ sort?.active === 'nomClient' ? '' : 'th_mat_cell_sort' }}"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        <div>Client</div>
      </th>
      <td
        mat-cell
        #tdElement
        *matCellDef="let row"
        class="column_content"
        matTooltip="{{ row.nomClient }}"
        [matTooltipDisabled]="!testIsOverflow(tdElement)"
      >
        {{ row.nomClient }}
      </td>
    </ng-container>
    <ng-container matColumnDef="statutRapprochementOk">
      <th mat-header-cell *matHeaderCellDef>
        <div>Rapprochement</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="capsule">
          <div
            class="caps {{
              row.statutRapprochementOk | rapprochementStatutClass: row.datePlusVieuxReglementNonRapproche
            }}"
          >
            @if (row.statutRapprochementOk) {
              <mat-icon>done</mat-icon>
            } @else {
              <mat-icon>close</mat-icon>
            }

            <p>{{ row.statutRapprochementOk ? 'ok' : 'ko' }}</p>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="montantTotal">
      <th mat-header-cell *matHeaderCellDef>
        <div class="header_With_icon right">
          Montant total
          <mat-icon matTooltip="Montant total encaissé pour le client" aria-hidden="false" class="icon_tooltip_info"
            >info
          </mat-icon>
        </div>
      </th>
      <td mat-cell *matCellDef="let row" class="aligne_D">{{ row.montantTotal.toFixed(2) }}€</td>
    </ng-container>
    <ng-container matColumnDef="totalReverse">
      <th mat-header-cell *matHeaderCellDef>
        <div class="header_With_icon">
          Total reversé
          <mat-icon
            matTooltip="Montant total reversé au client + commissions Tick&Live"
            aria-hidden="false"
            class="icon_tooltip_info"
            >info
          </mat-icon>
        </div>
      </th>
      <td mat-cell *matCellDef="let row" class="progress_bar_container">
        <div
          class="progress_bar {{
            (row.totalReverse / (row.totalReverse + row.montantDu)) * 100 === 100 ? 'barre_ok' : ''
          }}"
        >
          <div class="progress_bar_montant">
            {{ row.totalReverse.toFixed(2) }}€
            <mat-progress-bar
              mode="determinate"
              value="
                    {{
                (row.totalReverse / (row.totalReverse + row.montantDu === 0 ? 1 : row.totalReverse + row.montantDu)) *
                  100
              }}
                  "
            ></mat-progress-bar>
          </div>
          <div class="progress_bar_content">
            @if (row.totalReverse + row.montantDu === 0) {
              {{ 0 | percent }}
            } @else {
              {{ row.totalReverse / (row.totalReverse + row.montantDu) | percent }}
            }
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="montantDu">
      <th mat-header-cell *matHeaderCellDef>
        <div class="header_With_icon right">Reste dû</div>
      </th>
      <td mat-cell *matCellDef="let row" class="aligne_D">{{ row.montantDu.toFixed(2) }}€</td>
    </ng-container>
    <ng-container matColumnDef="statutEvenement">
      <th mat-header-cell *matHeaderCellDef>
        <div>Statut</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="capsule">
          <div class="caps {{ row.statutEvenement | evenementStatutClass }}">
            <div>{{ row.statutEvenement | evenementStatut }}</div>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="slider">
      <th mat-header-cell *matHeaderCellDef class="headertest" aria-label="row actions">&nbsp;</th>
      <td
        mat-cell
        *matCellDef="let evt"
        (click)="$event.stopPropagation()"
        class="mouseEnter"
        [@mouseEnter]="evt === evenementEnter ? 'oui' : 'non'"
      >
        <div class="listbtn">
          @if (!evt.statutRapprochementOk) {
            <div class="btn">
              <a [routerLink]="['/rapprochement/rapprochement-manuel']" [queryParams]="{ codeClient: evt.nomClient }">
                <button type="button" mat-flat-button class="btn-secondaire">Vérifier les rapprochements</button>
              </a>
            </div>
          }
          @if (evt.montantDu > 0 && acomptePossible(evt.acompteEnCours, evt.statutEvenement)) {
            <div class="btn">
              <button type="button" (click)="demanderAcompte(evt)" mat-flat-button class="btn-secondaire">
                Faire une demande d'acompte
              </button>
            </div>
          }
          @if (annulationAcomptePossible(evt.acompteEnCours, evt.statutEvenement)) {
            <div class="btn">
              <button type="button" (click)="annulerAcompteEnCours(evt)" mat-flat-button class="btn-secondaire">
                Annuler l'acompte en cours
              </button>
            </div>
          }
          @if (evt.statutRapprochementOk && evenementARedditionner(evt.statutEvenement)) {
            <div class="btn">
              <button type="button" (click)="demanderReddition(evt)" mat-flat-button class="btn-primaire">
                Redditionner l'évènement
              </button>
            </div>
          }
          @if (annulationRedditionPossible(evt.statutEvenement)) {
            <div class="btn">
              <button type="button" (click)="annulerRedditionEnCours(evt)" mat-flat-button class="btn-secondaire">
                Annuler la reddition en cours
              </button>
            </div>
          }

          <button
            class="evt_icon_btn"
            mat-mini-fab
            aria-label="Voir les détails"
            (click)="afficherDetailEvenement(evt)"
          >
            <mat-icon>manage_search</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="nomColumnsAvecDetail"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: nomColumnsAvecDetail"
      class="element-row"
      [class.expanded-row]="evenementDetail === element"
      (mouseenter)="onMouseEnter(element)"
      (mouseleave)="onMouseLeave()"
      (click)="evenementDetail = evenementDetail === element ? null : element"
    ></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Aucune donnée trouvée</td>
    </tr>
  </table>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [pageSizeOptions]="[25, 50, 75, 100]"
    [pageIndex]="pageFilter().numeroPage"
    [pageSize]="pageFilter().nbrParPage"
    [length]="evenements().totalCount"
    aria-label="Select page of users"
  ></mat-paginator>
</div>
