import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, PercentPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatMomentDateModule, MomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { mouseEnterAnimation } from '../../../../partage/animations/animations';
import { FactureService } from '../../../facturation/facture.service';
import { PageFilter, SortFilter } from '../../global/global-reddition.interface';
import { RapprochementStatutClassPipe } from '../../global/pipe/rapprochement-statut-class.pipe';
import { AcompteAnnulationService } from '../dialog-acompte-annulation/acompte-annulation.service';
import { DialogAcompteAnnulationComponent } from '../dialog-acompte-annulation/dialog-acompte-annulation.component';
import { AnnulationAcompteData } from '../dialog-acompte-annulation/dialog-acompte-annulation.interface';
import { DialogAcompteRedditionValidationComponent } from '../dialog-acompte-reddition-validation/dialog-acompte-reddition-validation.component';
import {
  DialogDataInput,
  DialogReturn,
  TypeDialog,
} from '../dialog-acompte-reddition-validation/dialog-acompte-reddition.interface';
import { DialogRedditionAnnulationComponent } from '../dialog-reddition-annulation/dialog-reddition-annulation.component';
import { AnnulationRedditionData } from '../dialog-reddition-annulation/dialog-reddition-annulation.interface';
import { RedditionAnnulationService } from '../dialog-reddition-annulation/reddition-annulation-service';
import { AnnulationDialogDataInput, Evenement, StatutEvenement } from '../evenements.interface';
import { EvenementsService } from '../evenements.service';
import { EvenementStatutClassPipe } from '../pipe/evenement-statut-class.pipe';
import { EvenementStatutPipe } from '../pipe/evenement-statut.pipe';

//traduction pour pagination
@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  firstPageLabel = `Premère page`;
  itemsPerPageLabel = `Lignes par page:`;
  lastPageLabel = `Dernière page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 sur 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} sur ${amountPages}`;
  }
}

@Component({
  selector: 'app-evenements-liste',
  imports: [
    PercentPipe,
    EvenementStatutPipe,
    EvenementStatutClassPipe,
    MatTooltipModule,
    MatProgressBarModule,
    MatIconModule,
    MomentDateModule,
    DatePipe,
    MatSortModule,
    MatMomentDateModule,
    MatTableModule,
    RapprochementStatutClassPipe,
    MatPaginatorModule,
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './evenements-liste.component.html',
  styleUrl: './evenements-liste.component.scss',
  providers: [
    { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    provideMomentDateAdapter(),
  ],
  animations: [mouseEnterAnimation],
})
export class EvenementsListeComponent implements OnInit, AfterViewChecked {
  private evenementService = inject(EvenementsService);
  private acompteAnnulationService = inject(AcompteAnnulationService);
  private redditionAnnulationService = inject(RedditionAnnulationService);
  private factureService = inject(FactureService);
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  evenements = this.evenementService.evenement;
  sortFilter = this.evenementService.sortFilter;
  pageFilter = this.evenementService.pageFilter;
  voirDetailEvenement = this.evenementService.voirDetail;
  panelEvenement = this.evenementService.evenementSelection;
  voirPDF = this.factureService.voirPdf;
  evenementEnter: Evenement | null = null;
  evenementDetail: Evenement | null = null;
  private dialog = inject(MatDialog);

  nomColumns: string[] = [
    'nom',
    'idAparte',
    'date',
    'nomClient',
    'statutRapprochementOk',
    'montantTotal',
    'totalReverse',
    'montantDu',
    'statutEvenement',
  ];
  nomColumnsAvecDetail = [...this.nomColumns, 'slider'];

  private cdr = inject(ChangeDetectorRef);

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  handlePageEvent(e: PageEvent) {
    const filter: PageFilter = {
      nbrParPage: e.pageSize,
      numeroPage: e.pageIndex,
    };
    this.pageFilter.set(filter);
  }
  ngOnInit() {
    this.sort?.sortChange.subscribe(sort => {
      this.sortData(sort);
    });
  }
  sortData(sort: Sort) {
    let direction: number;
    switch (sort.direction) {
      case 'asc':
        direction = 0;
        break;
      case 'desc':
        direction = 1;
        break;
      default:
        direction = 0;
        break;
    }
    const filter: SortFilter = { orderingField: sort.active, orderingDirection: direction };
    this.sortFilter.set(filter);
  }

  to: string | number | NodeJS.Timeout | undefined;

  onMouseEnter(t: Evenement) {
    this.to = setTimeout(() => {
      this.evenementEnter = this.evenementEnter === t ? null : t;
    }, 1000);
  }

  onMouseLeave() {
    clearTimeout(this.to);
    this.evenementEnter = null;
  }

  testIsOverflow(el: HTMLElement): boolean {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    const isOverflow = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflow;
  }

  evenementARedditionner(evtStatut: StatutEvenement): boolean {
    return evtStatut === StatutEvenement.ARedditionner;
  }

  annulationRedditionPossible(evtStatut: StatutEvenement): boolean {
    return evtStatut === StatutEvenement.Redditionne;
  }

  acomptePossible(acompteEnCours: boolean, evtStatut: StatutEvenement): boolean {
    return (
      (evtStatut === StatutEvenement.Acompte ||
        evtStatut === StatutEvenement.EnCours ||
        evtStatut === StatutEvenement.AControler) &&
      !acompteEnCours
    );
  }

  annulationAcomptePossible(acompteEnCours: boolean, evtStatut: StatutEvenement): boolean {
    return acompteEnCours && evtStatut !== StatutEvenement.Redditionne && evtStatut !== StatutEvenement.Reverse;
  }

  annulerAcompteEnCours(evt: Evenement) {
    const dialogData: AnnulationDialogDataInput = {
      evenement: evt,
    };
    this.openDialogAnnulationAcompte(dialogData);
  }

  annulerRedditionEnCours(evt: Evenement) {
    const dialogData: AnnulationDialogDataInput = {
      evenement: evt,
    };
    this.openDialogAnnulationReddition(dialogData);
  }

  openDialogAnnulationAcompte(dialogData: AnnulationDialogDataInput) {
    this.dialog
      .open(DialogAcompteAnnulationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: AnnulationAcompteData) => {
        this.acompteAnnulationService.acompteAAnnuler.set(result);
      });
  }

  openDialogAnnulationReddition(dialogData: AnnulationDialogDataInput) {
    this.dialog
      .open(DialogRedditionAnnulationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: AnnulationRedditionData) => {
        this.redditionAnnulationService.redditionAAnnuler.set(result);
      });
  }

  demanderAcompte(evt: Evenement) {
    const dialogData: DialogDataInput = {
      evenement: evt,
      type: TypeDialog.acompte,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  demanderReddition(evt: Evenement) {
    const dialogData: DialogDataInput = {
      evenement: evt,
      type: TypeDialog.reddition,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  openDialogAcompteReddition(dialogData: DialogDataInput) {
    this.dialog
      .open(DialogAcompteRedditionValidationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: DialogReturn) => {
        if (result.valide) {
          if (dialogData.type === TypeDialog.acompte) {
            this.evenementService.acompteACreer.set({
              idEvenement: dialogData.evenement.id,
              idCompteBancaire: result.idCompteBancaire,
              montantAcompte: result.montantAcompte,
            });
          } else if (dialogData.type === TypeDialog.reddition) {
            this.evenementService.redditionACreer.set({
              idEvenement: dialogData.evenement.id,
              idCompteBancaire: result.idCompteBancaire,
            });
          }
        }
      });
  }

  afficherDetailEvenement(evenement: Evenement) {
    this.panelEvenement.set(evenement);
    this.voirDetailEvenement.set(true);
  }

  fermerPanelDetails() {
    this.voirPDF.set(false);
    this.voirDetailEvenement.set(false);
    this.panelEvenement.set(undefined);
  }
}
