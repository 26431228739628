import { FormControl } from '@angular/forms';

export interface Formule {
  id: string;
  nom: string;
  idAparte: number;
  date: Date;
  nomClient: string;
  statutRapprochementOk: boolean;
  datePlusVieuxReglementNonRapproche: Date;
  totalReverse: number;
  statutFormule: StatutFormule;
  montantDu: number;
  detail: string;
  montantTotal: number;
  acompteEnCours: boolean;
}

export enum StatutFormule {
  Acompte = 'Acompte',
  AcompteEnCours = 'AcompteEnCours',
  EnCours = 'EnCours',
  ARedditionner = 'ARedditionner',
  AControler = 'AControler',
  Redditionne = 'Redditionne',
  Reverse = 'Reverse',
}

export interface FormuleFilterForm {
  codeClients: FormControl<string[]>;
  rechercheFormule: FormControl<string>;
  aRedditionnerUniquement: FormControl<boolean>;
  estArchive: FormControl<boolean>;
  filtreDateDebut: FormControl<string>;
  filtreDateFin: FormControl<string>;
}

export interface FormuleFilter {
  codeClients: string[];
  rechercheFormule: string;
  aRedditionnerUniquement: boolean;
  estArchive: boolean;
  filtreDateDebut: string;
  filtreDateFin: string;
}

export interface FormuleDataTable {
  items: Formule[];
  page: {
    current: number;
    size: number;
  };
  totalCount: number;
}

export interface RedditionSuccess {
  idFormule: string;
  montantTotalCommissionsHT: number;
  montantTotalCommissionsTTC: number;
  montantReverseAuClient: number;
}

export interface RedditionCreation {
  idFormule: string;
  idCompteBancaire: string;
}

export interface AcompteSuccess {
  idAcompte: string;
  montantReverseAuClient: number;
}

export interface AcompteCreation {
  idFormule: string;
  montantAcompte: number;
  idCompteBancaire: string;
}

export interface RedditionStatut {
  idReddition: string;
  statutReddition: string;
}

export interface AcompteStatut {
  idAcompte: string;
  statutAcompte: string;
}

export interface AnnulationDialogDataInput {
  formule: Formule;
}
