import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { httpIntercepteur } from './HttpIntercepteurs';

@Injectable()
export class HttpIntercepteur implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return httpIntercepteur(req, (request: HttpRequest<unknown>) => next.handle(request));
  }
}
