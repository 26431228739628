import { animate, state, style, transition, trigger } from '@angular/animations';
import { UpperCasePipe } from '@angular/common';

import { Component, inject, model, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IbanAutoformatPipe } from '../../global/pipe/iban-autoformat.pipe';
import { CompteBancaireService } from './compte-bancaire.service';
import {
  CompteBancaire,
  CompteBancaireForm,
  DialogDataInput,
  DialogReturn,
} from './dialog-acompte-reddition.interface';

@Component({
  selector: 'app-dialog-acompte-reddition-validation',
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    IbanAutoformatPipe,
    UpperCasePipe,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './dialog-acompte-reddition-validation.component.html',
  styleUrl: './dialog-acompte-reddition-validation.component.scss',
  animations: [
    trigger('creerCompteBancaire', [
      state(
        'oui',
        style({
          opacity: '1',
          height: '330px',
          width: '100%',
        }),
      ),
      state(
        'non',
        style({
          opacity: '0',
          height: '0',
          width: '0',
        }),
      ),
      transition('oui => non', [animate('0.2s')]),
      transition('non => oui', [animate('0.2s')]),
    ]),
  ],
})
export class DialogAcompteRedditionValidationComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<DialogAcompteRedditionValidationComponent>);
  private compteBancaireService = inject(CompteBancaireService);
  readonly data = inject<DialogDataInput>(MAT_DIALOG_DATA);
  listeComtpeBancaire = this.compteBancaireService.compteBancaires;
  evenement = this.compteBancaireService.evenement;
  infoDialog = this.compteBancaireService.infosDialog;
  creerCompteBancaire = this.compteBancaireService.creerCompteBancaire;

  montantAcompte = new FormControl(
    { value: null, disabled: false },
    {
      nonNullable: true,
      validators: [Validators.required, Validators.min(1), this.validationMontantAcompte()],
    },
  );

  compteBancaire = new FormControl<CompteBancaire>(
    { value: {} as CompteBancaire, disabled: false },
    { validators: [this.valideCompteB()] },
  );

  compteBancaireForm: FormGroup<CompteBancaireForm> = new FormGroup<CompteBancaireForm>({
    nomTitulaire: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(3)],
      },
    ),
    iban: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z]{2}[0-9]{2}\\s?[a-zA-Z0-9]{4}\\s?[0-9]{4}\\s?[0-9]{3}([a-zA-Z0-9]\\s?[a-zA-Z0-9]{0,4}\\s?[a-zA-Z0-9]{0,4}\\s?[a-zA-Z0-9]{0,3})?$',
          ),
          this.ValideIban(),
        ],
      },
    ),
    bic: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9]{4}\\s?[A-Za-z]{2}\\s?[A-Za-z0-9]{2}\\s?[A-Za-z0-9]{0,3}$'),
        ],
      },
    ),
  });

  ngOnInit() {
    this.compteBancaireService.dialogData.set(this.data);
  }

  toggle() {
    this.creerCompteBancaire.set(!this.creerCompteBancaire());
  }

  isValid() {
    const test = this.compteBancaire.invalid;
    const test2 = this.listeComtpeBancaire() && this.listeComtpeBancaire()!.length === 0;
    const test3 = this.infoDialog().type === 'acompte' && this.montantAcompte!.invalid;

    return test || test2 || test3;
  }

  ajouterComtpeBancaire() {
    this.compteBancaireForm.markAllAsTouched();
    if (this.compteBancaireForm.valid) {
      const info = this.evenement();

      this.compteBancaireService.compteBancaireAjout.set({
        idClient: info.nomClient,
        iban: this.compteBancaireForm.value.iban!.replaceAll(' ', ''),
        bic: this.compteBancaireForm.value.bic!.replaceAll(' ', ''),
        nomTitulaire: this.compteBancaireForm.value.nomTitulaire!,
      });
    }
  }

  SubmitValue() {
    this.dialogRef.close({
      valide: true,
      montantAcompte: this.montantAcompte?.value,
      idCompteBancaire: this.compteBancaire.value?.compteBancaireId, // this.id.value.replace(' ', ''),
    });
  }

  readonly refuse = model<DialogReturn>({
    valide: false,
    montantAcompte: 0,
    idCompteBancaire: '',
  });

  ValideIban(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const codeLengths = {
        AD: 24,
        AE: 23,
        AL: 28,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        LC: 30,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
      };
      if (control.value) {
        const value = control.value.replace(' ', '');
        const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        if (!code || iban.length !== (codeLengths as never)[code[1]]) {
          return { ValideIban: true };
        }
        const digits: number = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
          return letter.charCodeAt(0) - 55;
        });
        return this.mod97(digits) === 1 ? null : { ValideIban: true };
      }
      return null;
    };
  }

  valideCompteB(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as CompteBancaire;
      if (value.compteBancaireId) {
        return value.compteBancaireId === '' ? { valideCB: false } : null;
      }
      return { valideCB: false };
    };
  }

  validationMontantAcompte(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value as number) {
        return value <= this.infoDialog().MontantMaxAcompte ? null : { valideMontant: true };
      }
      return null;
    };
  }

  mod97(digital: number | string) {
    digital = digital.toString();
    let checksum: number | string = digital.slice(0, 2);
    let fragment = '';
    for (let offset = 2; offset < digital.length; offset += 7) {
      fragment = checksum + digital.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }
}
