import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { AfterViewInit, Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { Client } from '../../global/global-reddition.interface';
import { AcompteAnnulationService } from '../dialog-acompte-annulation/acompte-annulation.service';
import { RedditionAnnulationService } from '../dialog-reddition-annulation/reddition-annulation-service';
import { EvenementFilterForm } from '../evenements.interface';
import { EvenementsService } from '../evenements.service';
import { DateRangePickerComponent } from '../../../../partage/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'app-evenement-filtre',
  imports: [
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    DateRangePickerComponent,
  ],
  templateUrl: './evenement-filtre.component.html',
  styleUrl: './evenement-filtre.component.scss',
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    provideMomentDateAdapter(),
  ],
})
export class EvenementFiltreComponent implements AfterViewInit {
  private evenementService = inject(EvenementsService);
  private acompteAnnulationService = inject(AcompteAnnulationService);
  private redditionAnnulationService = inject(RedditionAnnulationService);
  private route = inject(ActivatedRoute);
  evenements = this.evenementService.evenement;
  filteredClient = this.evenementService.filteredClient;
  clientSelection = this.evenementService.clientSelection;
  aRedditionnerIsChecked = false;
  estArchiveIsChecked = false;

  filterForm: FormGroup<EvenementFilterForm>;

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const paramClient = params.get('codeClient');
      const paramARedditionner = params.get('aRedditionner');

      this.evenementService.urlClient.set(paramClient ?? '');

      if (paramARedditionner === 'true') {
        this.toggleARedditionnerChange();
      }
    });
  }

  clearClient() {
    this.clientSelection.set(undefined);
  }

  displayClient(client?: Client): string {
    return client?.libelleClient ? client.libelleClient : '';
  }

  clearEvenement() {
    this.filterForm.controls.rechercheEvenement.setValue('');
  }
  clearDate() {
    this.filterForm.controls.filtreDateDebut.reset();
    this.filterForm.controls.filtreDateFin.reset();
  }

  toggleARedditionnerChange() {
    this.aRedditionnerIsChecked = !this.aRedditionnerIsChecked;
    this.clearClient();
    this.filterForm.reset();
    this.filterForm.controls.aRedditionnerUniquement.setValue(this.aRedditionnerIsChecked);
    this.estArchiveIsChecked = false;
    this.filterForm.controls.estArchive.setValue(false);
  }

  toggleEstArchiveChange() {
    this.estArchiveIsChecked = !this.estArchiveIsChecked;
    this.filterForm.controls.estArchive.setValue(this.estArchiveIsChecked);
    this.aRedditionnerIsChecked = false;
    this.filterForm.controls.aRedditionnerUniquement.setValue(false);
  }

  constructor() {
    this.filterForm = new FormGroup<EvenementFilterForm>({
      codeClients: new FormControl<string[]>(
        {
          value: [],
          disabled: false,
        },
        { nonNullable: true },
      ),
      rechercheEvenement: new FormControl<string>(
        { value: '', disabled: false },
        { nonNullable: true, validators: [Validators.minLength(3)] },
      ),
      aRedditionnerUniquement: new FormControl<boolean>({ value: false, disabled: false }, { nonNullable: true }),
      estArchive: new FormControl<boolean>({ value: false, disabled: false }, { nonNullable: true }),
      filtreDateDebut: new FormControl<string>({ value: '', disabled: false }, { nonNullable: true }),
      filtreDateFin: new FormControl<string>({ value: '', disabled: false }, { nonNullable: true }),
    });

    this.filterForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      if (this.filterForm.valid) {
        this.evenementService.filterform.set(this.filterForm.getRawValue());
      }
    });

    effect(() => {
      this.filterForm.controls.codeClients.patchValue(
        this.clientSelection() ? [this.clientSelection()!.codeClient] : [],
        { emitEvent: true },
      );
    });

    effect(() => {
      if (this.acompteAnnulationService.acompteAnnule()) {
        this.filterForm.reset(this.filterForm.value);
        this.acompteAnnulationService.acompteAnnule.set(false);
      }
    });

    effect(() => {
      if (this.redditionAnnulationService.redditionAnnulee()) {
        this.filterForm.reset(this.filterForm.value);
        this.redditionAnnulationService.redditionAnnulee.set(false);
      }
    });
  }
}
