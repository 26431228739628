import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { AjoutParametragesSpecifiquesEvenementRequetePayload, Evenement } from '../../../commissions.interface.';
import { CommissionsService } from '../../../commissions.service';
import { MatInput } from '@angular/material/input';
import { MatList, MatListItem } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'app-ajouter-commission-evenement',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInput,
    MatList,
    MatListItem,
    MatTooltip,
    DatePipe,
    MatChip,
  ],
  templateUrl: './ajouter-commission-evenement.component.html',
  styleUrl: './ajouter-commission-evenement.component.scss',
})
export class AjouterCommissionEvenementComponent {
  private commissionsService = inject(CommissionsService);
  evenementsSelectionnes = new FormControl<Evenement[]>([]);

  voirPanel = this.commissionsService.voirPanelAjoutParametrageSpecifiqueEvenement;
  evenements = this.commissionsService.evenements;

  reglesForm = new FormGroup({
    reglePourPlaces: new FormGroup({
      pourcentage: new FormControl(0),
      minimum: new FormControl(0, [Validators.min(0), Validators.max(100)]),
    }),
  });

  constructor() {
    effect(() => {
      if (this.voirPanel()) {
        this.commissionsService.recupererLesEvenementsPourParametrage().subscribe();
      }
    });
  }

  retirerEvenementDeLaSelection(event: Evenement) {
    const currentEvents = this.evenementsSelectionnes.value ?? [];
    const updatedEvents = currentEvents.filter(e => e.idEvenement !== event.idEvenement);
    this.evenementsSelectionnes.setValue(updatedEvents);
  }

  reglePourPlacesLibelle(): string {
    if (this.reglesForm.value.reglePourPlaces?.pourcentage) {
      return `Pour chaque place vendue, la commission web Tick&Live est de ${this.reglesForm.value.reglePourPlaces.pourcentage}% avec un minimum de ${this.reglesForm.value.reglePourPlaces.minimum}€ `;
    }

    return `Pour chaque place vendue, la commission web Tick&Live est de ${this.reglesForm.value.reglePourPlaces?.minimum}€ (fixe)`;
  }

  fermerSlider() {
    this.voirPanel.set(false);
  }

  valider() {
    if (this.reglesForm.valid && this.evenementsSelectionnes?.value?.length) {
      const evenementIds = this.evenementsSelectionnes.value.map(x => x.idEvenement);
      const { reglePourPlaces } = this.reglesForm.value;

      // pourcentage et minimum à 0 pour les produits car pas geré pour le moment
      const payload: AjoutParametragesSpecifiquesEvenementRequetePayload = {
        idsEvenements: evenementIds,
        reglePourPlaces: { minimum: reglePourPlaces?.minimum ?? 0, pourcentage: reglePourPlaces?.pourcentage ?? 0 },
        reglePourProduits: { minimum: 0, pourcentage: 0 },
      };

      this.commissionsService.parametrageSpecifiqueAAjouter.set(payload);
      this.fermerSlider();
    }
  }
}
