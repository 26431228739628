import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, switchMap, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  AnnulationRedditionData,
  InfoRedditionEnCours,
} from '../dialog-reddition-annulation/dialog-reddition-annulation.interface';

import { Formule } from '../formules.interface';

@Injectable({
  providedIn: 'root',
})
export class RedditionAnnulationService {
  private httpClient = inject(HttpClient);
  private formule = signal<Formule | undefined>(undefined);

  redditionAAnnuler = signal<AnnulationRedditionData | undefined>(undefined);
  redditionAnnulee = signal<boolean>(false);

  private creationReddition = toSignal(
    toObservable(this.redditionAAnnuler).pipe(
      filter(a => !!a),
      switchMap(a =>
        this.httpClient.post<InfoRedditionEnCours>(
          `${environment.backendUrl}redditions/reddition/${a.idReddition}/annuler`,
          {
            commentaire: a.commentaire,
          },
        ),
      ),
      tap(() => this.redditionAnnulee.set(true)),
    ),
  );

  setFormule(formule: Formule) {
    this.formule.set(formule);
  }

  infosRedditionEnCours = toSignal(
    toObservable(this.formule).pipe(
      filter(evt => !!evt),
      switchMap(evt =>
        this.httpClient.get<InfoRedditionEnCours>(
          `${environment.backendUrl}redditions/formules/${evt!.id}/reddition-en-cours`,
        ),
      ),
    ),
  );
}
