import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, delay, finalize, Observable, of, retryWhen, scan } from 'rxjs';
import { AlertService } from '../../components/alert/alert.service';
import { ChargementService } from '../../components/chargement/chargement.service';

const RETRY_TIMES = 5;
const DELAY = 2000;

export function httpIntercepteur(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const isLoading = inject(ChargementService);
  const alert = inject(AlertService);

  function handleClientError(error: ErrorEvent, errorsBase: string): string {
    console.log('An error occurred:', error.message);
    return `Client-side error: ${error.message} : ${errorsBase}`;
  }

  function handleHttpError(error: HttpErrorResponse, errorsBase: string): string {
    switch (error.status) {
      case HttpStatusCode.Unauthorized:
        console.error('Unauthorized:', errorsBase);
        return `Unauthorized: ${errorsBase}`;
      case HttpStatusCode.Forbidden:
        console.error('Forbidden:', errorsBase);
        return `Forbidden: ${errorsBase}`;
      case HttpStatusCode.NotFound:
        console.error('Not Found:', errorsBase);
        return `Not Found: ${errorsBase}`;
      case HttpStatusCode.ServiceUnavailable:
        console.error('Service Unavailable:', errorsBase);
        return `Service Unavailable: ${errorsBase}`;
      default:
        console.error(errorsBase || 'Une erreur est survenue');
        return errorsBase || 'Une erreur est survenue';
    }
  }

  function isInstanceOfBlobAndTypeIncludesProblemJson(error: HttpErrorResponse) {
    return error.error instanceof Blob && error.error.type.includes('application/problem+json');
  }

  function handleApplicationProblemJson(error: HttpErrorResponse) {
    return new Observable<never>(observer => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonResponse = JSON.parse(reader.result as string);
          const formattedError = new HttpErrorResponse({
            error: jsonResponse,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url!,
          });
          observer.error(formattedError);
        } catch {
          observer.error(error);
        }
      };
      reader.readAsText(error.error);
    }).pipe(
      catchError(err => {
        alert.error(err.error?.errors[0]?.description, {
          autoClose: false,
          keepAfterRouteChange: true,
        });
        return of();
      }),
    );
  }

  function getErrorMessage(error: any, errorsBase: string): string {
    if (error.error instanceof ErrorEvent && error.error) {
      return handleClientError(error.error, errorsBase);
    } else {
      return handleHttpError(error, errorsBase);
    }
  }

  let isSuccess: boolean = true;

  const to = setTimeout(() => {
    isLoading.isLoading.set(true);
  }, 700);

  return next(req).pipe(
    retryWhen(errors =>
      errors.pipe(
        scan((retryCount, error) => {
          if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
            throw error;
          }
          if (retryCount >= RETRY_TIMES) {
            throw error;
          }
          return retryCount + 1;
        }, 0),
        delay(DELAY),
      ),
    ),

    catchError(error => {
      console.log(error);
      let errorMessage = 'An unexpected error has occurred.';

      if (error instanceof HttpErrorResponse) {
        if (isInstanceOfBlobAndTypeIncludesProblemJson(error)) {
          return handleApplicationProblemJson(error);
        }

        const errorsBase = error.error?.errors
          ? error.error?.errors[0]?.description
          : 'Une erreur est survenue, contactez le support.';

        errorMessage = getErrorMessage(error, errorsBase);
      } else {
        console.error(errorMessage);
      }
      isSuccess = false;
      alert.error(errorMessage, {
        autoClose: false,
        keepAfterRouteChange: true,
      });

      return of();
    }),
    finalize(() => {
      if ((req.method === 'POST' || req.method === 'PUT') && isSuccess) {
        alert.success(`Votre demande a été traitée avec succès`, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }
      clearTimeout(to);
      isLoading.isLoading.set(false);
    }),
  );
}
