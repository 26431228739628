import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, switchMap, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Evenement } from '../evenements.interface';
import { AnnulationAcompteData, InfoAcompteEnCours } from '../../global/acompte-annulation.interface';

@Injectable({
  providedIn: 'root',
})
export class AcompteAnnulationService {
  private httpClient = inject(HttpClient);
  private evenement = signal<Evenement | undefined>(undefined);

  acompteAAnnuler = signal<AnnulationAcompteData | undefined>(undefined);
  acompteAnnule = signal<boolean>(false);

  private creationAcompte = toSignal(
    toObservable(this.acompteAAnnuler).pipe(
      filter(a => !!a),
      switchMap(a =>
        this.httpClient.post<InfoAcompteEnCours>(`${environment.backendUrl}redditions/acompte/${a.idAcompte}/annuler`, {
          commentaire: a.commentaire,
        }),
      ),
      tap(() => this.acompteAnnule.set(true)),
    ),
  );

  setEvenement(evenement: Evenement) {
    this.evenement.set(evenement);
  }

  infosAcompteEnCours = toSignal(
    toObservable(this.evenement).pipe(
      filter(evt => !!evt),
      switchMap(evt =>
        this.httpClient.get<InfoAcompteEnCours>(
          `${environment.backendUrl}redditions/evenements/${evt!.id}/acompte-en-cours`,
        ),
      ),
    ),
  );
}
