import { animate, state, style, transition, trigger } from '@angular/animations';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, PercentPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatMomentDateModule, MomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { FactureService } from '../../../facturation/facture.service';
import { AcompteAnnulationService } from '../../evenements/dialog-acompte-annulation/acompte-annulation.service';
import { RedditionAnnulationService } from '../../evenements/dialog-reddition-annulation/reddition-annulation-service';
import { PageFilter, SortFilter } from '../../global/global-reddition.interface';
import { RapprochementStatutClassPipe } from '../../global/pipe/rapprochement-statut-class.pipe';
import { DialogAcompteAnnulationComponent } from '../dialog-acompte-annulation/dialog-acompte-annulation.component';
import { AnnulationAcompteData } from '../dialog-acompte-annulation/dialog-acompte-annulation.interface';
import { DialogAcompteRedditionValidationComponent } from '../dialog-acompte-reddition-validation/dialog-acompte-reddition-validation.component';
import {
  DialogDataInput,
  DialogReturn,
  TypeDialog,
} from '../dialog-acompte-reddition-validation/dialog-acompte-reddition.interface';
import { DialogRedditionAnnulationComponent } from '../dialog-reddition-annulation/dialog-reddition-annulation.component';
import { AnnulationRedditionData } from '../dialog-reddition-annulation/dialog-reddition-annulation.interface';
import { AnnulationDialogDataInput, Formule, StatutFormule } from '../formules.interface';
import { FormulesService } from '../formules.service';
import { FormuleStatutClassPipe } from '../pipe/formule-statut-class.pipe';
import { FormuleStatutPipe } from '../pipe/formule-statut.pipe';

//traduction pour pagination
@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  firstPageLabel = `Premère page`;
  itemsPerPageLabel = `Lignes par page:`;
  lastPageLabel = `Dernière page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 sur 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} sur ${amountPages}`;
  }
}

@Component({
  selector: 'app-formules-liste',
  imports: [
    PercentPipe,
    MatTooltipModule,
    MatProgressBarModule,
    MatIconModule,
    MomentDateModule,
    DatePipe,
    MatSortModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    MatButtonModule,
    RapprochementStatutClassPipe,
    FormuleStatutClassPipe,
    FormuleStatutPipe,
  ],
  templateUrl: './formules-liste.component.html',
  styleUrl: './formules-liste.component.scss',
  providers: [
    { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    provideMomentDateAdapter(),
  ],
  animations: [
    trigger('mouseEnter', [
      state('oui', style({ maxWidth: '800px', minWidth: '40%' })),
      state('non', style({ width: '0px', minWidth: '0' })),
      transition('oui <=> non', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('slider', [
      state('open', style({ width: '70%', minWidth: '70%' })),
      state('close', style({ width: '0', minWidth: '0' })),
      transition('open <=> close', [animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
    trigger('showDetail', [
      state('true', style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })),
      state('false', style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '0' })),
      transition('false => true', [animate('0.4s ease-out')]),
    ]),
  ],
})
export class FormulesListeComponent implements OnInit, AfterViewChecked {
  private formuleService = inject(FormulesService);
  private acompteAnnulationService = inject(AcompteAnnulationService);
  private redditionAnnulationService = inject(RedditionAnnulationService);
  private factureService = inject(FactureService);
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  formules = this.formuleService.formules;
  sortFilter = this.formuleService.sortFilter;
  pageFilter = this.formuleService.pageFilter;
  voirDetail = this.formuleService.voirDetail;
  panelFormule = this.formuleService.formuleSelection;
  voirPDF = this.factureService.voirPdf;
  formuleEnter: Formule | null = null;
  formuleDetail: Formule | null = null;
  private dialog = inject(MatDialog);

  nomColumns: string[] = [
    'nom',
    'idAparte',
    'date',
    'nomClient',
    'statutRapprochementOk',
    'montantTotal',
    'totalReverse',
    'montantDu',
    'statutFormule',
  ];
  nomColumnsAvecDetail = [...this.nomColumns, 'slider'];

  private cdr = inject(ChangeDetectorRef);

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  handlePageEvent(e: PageEvent) {
    const filter: PageFilter = {
      nbrParPage: e.pageSize,
      numeroPage: e.pageIndex,
    };
    this.pageFilter.set(filter);
  }
  ngOnInit() {
    this.sort?.sortChange.subscribe(sort => {
      this.sortData(sort);
    });
  }

  to: string | number | NodeJS.Timeout | undefined;

  onMouseEnter(t: Formule) {
    this.to = setTimeout(() => {
      this.formuleEnter = this.formuleEnter === t ? null : t;
    }, 1000);
  }

  onMouseLeave() {
    clearTimeout(this.to);
    this.formuleEnter = null;
  }

  sortData(sort: Sort) {
    let direction: number;
    switch (sort.direction) {
      case 'asc':
        direction = 0;
        break;
      case 'desc':
        direction = 1;
        break;
      default:
        direction = 0;
        break;
    }
    const filter: SortFilter = { orderingField: sort.active, orderingDirection: direction };
    this.sortFilter.set(filter);
  }

  testIsOverflow(el: HTMLElement): boolean {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    const isOverflow = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflow;
  }

  afficherDetailFormule(formule: Formule) {
    this.panelFormule.set(formule);
    this.voirDetail.set(true);
  }
  acomptePossible(acompteEnCours: boolean, formuleStatut: StatutFormule): boolean {
    return (
      (formuleStatut === StatutFormule.Acompte ||
        formuleStatut === StatutFormule.EnCours ||
        formuleStatut === StatutFormule.AControler) &&
      !acompteEnCours
    );
  }

  demanderAcompte(formule: Formule) {
    const dialogData: DialogDataInput = {
      formule: formule,
      type: TypeDialog.acompte,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  demanderReddition(formule: Formule) {
    const dialogData: DialogDataInput = {
      formule: formule,
      type: TypeDialog.reddition,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  openDialogAcompteReddition(dialogData: DialogDataInput) {
    this.dialog
      .open(DialogAcompteRedditionValidationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: DialogReturn) => {
        if (result.valide) {
          if (dialogData.type === TypeDialog.acompte) {
            this.formuleService.acompteACreer.set({
              idFormule: dialogData.formule.id,
              idCompteBancaire: result.idCompteBancaire,
              montantAcompte: result.montantAcompte,
            });
          } else if (dialogData.type === TypeDialog.reddition) {
            this.formuleService.redditionACreer.set({
              idFormule: dialogData.formule.id,
              idCompteBancaire: result.idCompteBancaire,
            });
          }
        }
      });
  }

  annulationRedditionPossible(formuleStatut: StatutFormule): boolean {
    return formuleStatut === StatutFormule.Redditionne;
  }

  annulationAcomptePossible(acompteEnCours: boolean, formuleStatut: StatutFormule): boolean {
    return acompteEnCours && formuleStatut !== StatutFormule.Redditionne && formuleStatut !== StatutFormule.Reverse;
  }

  annulerAcompteEnCours(formule: Formule) {
    const dialogData: AnnulationDialogDataInput = {
      formule: formule,
    };
    this.openDialogAnnulationAcompte(dialogData);
  }

  annulerRedditionEnCours(formule: Formule) {
    const dialogData: AnnulationDialogDataInput = {
      formule: formule,
    };
    this.openDialogAnnulationReddition(dialogData);
  }

  formuleARedditionner(formuleStatut: StatutFormule): boolean {
    return formuleStatut === StatutFormule.ARedditionner;
  }

  openDialogAnnulationAcompte(dialogData: AnnulationDialogDataInput) {
    this.dialog
      .open(DialogAcompteAnnulationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: AnnulationAcompteData) => {
        this.acompteAnnulationService.acompteAAnnuler.set(result);
      });
  }

  openDialogAnnulationReddition(dialogData: AnnulationDialogDataInput) {
    this.dialog
      .open(DialogRedditionAnnulationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: AnnulationRedditionData) => {
        this.redditionAnnulationService.redditionAAnnuler.set(result);
      });
  }
}
