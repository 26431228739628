import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AnnulationDialogDataInput } from '../evenements.interface';
import { AcompteAnnulationService } from './acompte-annulation.service';
import { AnnulationForm } from '../../global/acompte-annulation.interface';

@Component({
  selector: 'app-dialog-acompte-reddition-annulation',
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    MatInputModule,
  ],
  templateUrl: './dialog-acompte-annulation.component.html',
  styleUrl: './dialog-acompte-annulation.component.scss',
})
export class DialogAcompteAnnulationComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<DialogAcompteAnnulationComponent>);
  readonly data = inject<AnnulationDialogDataInput>(MAT_DIALOG_DATA);
  readonly annulationAcompteService = inject(AcompteAnnulationService);

  infosAcompteEnCours = this.annulationAcompteService.infosAcompteEnCours;

  annulationForm: FormGroup<AnnulationForm> = new FormGroup<AnnulationForm>({
    commentaire: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(3)],
      },
    ),
  });

  ngOnInit(): void {
    this.annulationAcompteService.setEvenement(this.data.evenement);
  }

  submit(): void {
    this.dialogRef.close({
      idAcompte: this.infosAcompteEnCours()!.idAcompte,
      commentaire: this.annulationForm.value.commentaire,
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
