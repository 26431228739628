import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogSubmitComponent } from '../../../partage/components/dialog-submit/dialog-submit.component';
import { dialogSubmitData } from '../../../partage/components/dialog-submit/dialog-submit.interfaces';
import { AjouterCommissionEvenementComponent } from './ajouter-commission/evenement/ajouter-commission-evenement/ajouter-commission-evenement.component';
import { Client, ParametrageSpecifiqueEvenement } from './commissions.interface.';
import { CommissionsService } from './commissions.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-commissions.route',
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    FormsModule,
    MatDividerModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AjouterCommissionEvenementComponent,
    DatePipe,
    ReactiveFormsModule,
  ],
  templateUrl: './commissions.route.component.html',
  styleUrl: './commissions.route.component.scss',
  animations: [
    trigger('showPanel', [
      state('true', style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })),
      state('false', style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '-10' })),
      transition('false => true', [animate('0.4s ease-out')]),
    ]),
    trigger('slider', [
      state('open', style({ width: '50%', minWidth: '50%' })),
      state('close', style({ width: '0', minWidth: '0' })),
      transition('open <=> close', [animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
  ],
})
export class CommissionsRouteComponent {
  private commissionsService = inject(CommissionsService);
  private dialog = inject(MatDialog);
  nomColonne: string[] = ['nom', 'idAparte', 'date', 'regle'];
  nomColonneAvecBouton: string[] = [...this.nomColonne, 'btnModif'];

  filtreParametrageSpecifiqueEvenementControl = new FormControl('');

  parametragesSpecifiquesEvenement = this.commissionsService.parametragesSpecifiquesEvenement;
  parametragesGlobauxClient = this.commissionsService.parametragesGlobauxClient;

  clientSelection = this.commissionsService.clientSelection;
  filteredClient = this.commissionsService.filteredClient;
  voirPanel = this.commissionsService.voirPanelAjoutParametrageSpecifiqueEvenement;

  filtreParametrageSpecifiqueEvenement = this.commissionsService.filtreParametrageSpecifiqueEvenement;

  parametragesSpecifiquesEvenementFiltres = this.commissionsService.parametragesSpecifiquesEvenementFiltres;

  constructor() {
    this.filtreParametrageSpecifiqueEvenementControl.valueChanges.subscribe(value => {
      this.filtreParametrageSpecifiqueEvenement.set(value || '');
    });
  }

  clearClient() {
    this.clientSelection.set(undefined);
    this.filtreParametrageSpecifiqueEvenementControl.setValue('');
  }

  displayClient(client?: Client): string {
    return client?.libelleClient ? client.libelleClient : '';
  }

  fermerPanel() {
    this.voirPanel.set(false);
  }

  recupererLibellePourRegle(minimum: number, pourcentage: number): string {
    if (pourcentage > 0) {
      return `Pour chaque place vendue, la commission web Tick&Live est de  ${pourcentage}%  avec un minimum de  ${minimum}€.`;
    }

    return `Pour chaque place vendue, la commission web Tick&Live est de ${minimum}€ (fixe).`;
  }

  openDialog(parametrage: ParametrageSpecifiqueEvenement) {
    const dialogData: dialogSubmitData = {
      btnAnnuler: 'Annuler',
      btnValide: 'Confirmer la suppression',
      message: `Vous êtes sur le point de supprimer la règle spécifique pour l'évènement : ${parametrage.nomEvenement}`,
    };

    this.dialog
      .open(DialogSubmitComponent, { data: dialogData })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.commissionsService.parametrageSpecifiqueASupprimer.set(parametrage);
        }
      });
  }
}
