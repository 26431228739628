import { HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Evenement } from '../evenements.interface';
import {
  AjoutCompteBancaire,
  CompteBancaire,
  ComptesBancairesListe,
  DialogAcompteRedditionData,
  DialogDataInput,
  PreviewAcompte,
  PreviewReddition,
  TypeDialog,
} from './dialog-acompte-reddition.interface';

@Injectable({
  providedIn: 'root',
})
export class CompteBancaireService {
  private _httpClient = inject(HttpClient);

  dialogData = signal<DialogDataInput | undefined>(undefined);
  evenement = computed(() => (this.dialogData() ? this.dialogData()!.evenement : ({} as Evenement)));
  type = computed(() => this.dialogData()?.type);

  recupererInfoAcompte$ = toObservable(this.dialogData).pipe(
    filter(dial => dial != undefined && dial.type === TypeDialog.acompte),
    map(d => d?.evenement),
    distinctUntilChanged((x, y) => {
      return x?.id === y?.id;
    }),
    switchMap(x => {
      const href = `${environment.backendUrl}redditions/evenements/${x?.id}/preview-acompte`;
      return this._httpClient.get<PreviewAcompte>(href);
    }),
  );

  recupererInfoAcompte = toSignal(this.recupererInfoAcompte$);
  infoAcompte = computed(() => (this.recupererInfoAcompte() ? this.recupererInfoAcompte()! : ({} as PreviewAcompte)));

  reloadComtpe = signal<boolean>(true);

  compteBancaireComputed = computed(() => ({
    evenement: this.evenement(),
    reload: this.reloadComtpe(),
  }));

  private recuperationCompteBancaire = toSignal(
    toObservable(this.compteBancaireComputed).pipe(
      filter(cb => cb.evenement.nomClient !== undefined && cb.reload),
      switchMap(cb => {
        const href = `${environment.backendUrl}redditions/client/${cb.evenement.nomClient}/liste-comptes-bancaires`;
        return this._httpClient.get<ComptesBancairesListe>(href).pipe(
          map(compte => {
            this.compteBancaires.set(compte.comptesBancaires);
            this.reloadComtpe.set(false);
          }),
        );
      }),
    ),
  );

  compteBancaires = signal<CompteBancaire[]>([]);

  recupererInfoReddition$ = toObservable(this.dialogData).pipe(
    filter(dial => dial != undefined && dial.type === TypeDialog.reddition),
    map(d => d!.evenement),
    distinctUntilChanged((x, y) => {
      return x?.id === y?.id;
    }),
    switchMap(x => {
      const href = `${environment.backendUrl}redditions/evenements/${x.id}/preview-reddition`;
      return this._httpClient.get<PreviewReddition>(href);
    }),
  );

  recupererInfoReddition = toSignal(this.recupererInfoReddition$);
  infoReddition = computed(() =>
    this.recupererInfoReddition() ? this.recupererInfoReddition()! : ({} as PreviewReddition),
  );

  compteBancaireAjout = signal<AjoutCompteBancaire | undefined>(undefined);
  creerCompteBancaire = signal<boolean>(false);

  private ajoutCompteBancaire = toSignal(
    toObservable(this.compteBancaireAjout).pipe(
      filter(Boolean),
      switchMap(compteBancaire => {
        return this.ajouterCompteBancaire$(compteBancaire).pipe(
          map(() => {
            this.creerCompteBancaire.set(false);
            this.reloadComtpe.set(true);
          }),
        );
      }),
    ),
  );

  ajouterCompteBancaire$(compte: AjoutCompteBancaire) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const href = `${environment.backendUrl}redditions/clients/${compte.idClient}/ajouter-compte-bancaire`;

    return this._httpClient.post(
      href,
      { nomTitulaire: compte.nomTitulaire, iban: compte.iban, bic: compte.bic },
      httpOptions,
    );
  }

  infosDialog = computed(() => {
    const evt = this.evenement();
    const type = this.type();
    if (type === TypeDialog.acompte) {
      const infoAcompte = this.recupererInfoAcompte();
      if (infoAcompte) {
        const data: DialogAcompteRedditionData = {
          btnValide: `Créer l'acompte`,
          btnAnnuler: 'Annuler',
          type: 'acompte',
          idClient: evt.nomClient,
          MontantMaxAcompte: parseFloat(infoAcompte.montantReverseAuClient.toFixed(2)),
          message: `Vous êtes sur le point de générer un acompte sur l'évènement
          <br><strong>${evt.nom}</strong><br>
          Numéro de session <strong>${evt.idAparte}</strong><br>
          Montant global du reversement à effectuer : <strong>${infoAcompte.montantReverseAuClient.toLocaleString(
            'fr-FR',
            {
              style: 'currency',
              currency: 'EUR',
            },
          )}</strong><br>
          Montant global de la commission Tick&Live TTC : ${infoAcompte.montantTotalCommissionsTTC.toLocaleString(
            'fr-FR',
            { style: 'currency', currency: 'EUR' },
          )}<br>
          Pour chaque place vendue, ${infoAcompte.libelleRegleCalculCommissionsPlace}
          <br>
          Pour chaque produit vendu, ${infoAcompte.libelleRegleCalculCommissionsProduit}
          <br>
          ${infoAcompte.estSpecifique ? `La règle est spécifique pour l'évenement` : ''}`,
        };
        return data;
      }
    } else if (type === TypeDialog.reddition) {
      const infoReddition = this.recupererInfoReddition();
      if (infoReddition) {
        const data: DialogAcompteRedditionData = {
          btnValide: `Lancer la reddition`,
          btnAnnuler: 'Annuler',
          type: 'reddition',
          idClient: evt.nomClient,
          MontantMaxAcompte: 0,
          message: `Vous êtes sur le point de générer une reddition sur l'évènement
          <br><strong>${evt.nom}</strong><br> Numéro de session <strong>${evt.idAparte}</strong><br>
        Montant global du reversement à effectuer : <strong>${infoReddition.montantReverseAuClient.toLocaleString(
          'fr-FR',
          {
            style: 'currency',
            currency: 'EUR',
          },
        )}</strong><br>
        Montant global de la commission Tick&Live TTC : ${infoReddition.montantTotalCommissionsTTC.toLocaleString(
          'fr-FR',
          { style: 'currency', currency: 'EUR' },
        )}<br>
        Pour chaque place vendue, ${infoReddition.libelleRegleCalculCommissionsPlace}
          <br>
          Pour chaque produit vendu, ${infoReddition.libelleRegleCalculCommissionsProduit}
          <br>
          ${infoReddition.estSpecifique ? `La règle est spécifique pour l'évenement` : ''}`,
        };
        return data;
      }
    }

    return {} as DialogAcompteRedditionData;
  });
}
