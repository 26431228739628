import { FormControl } from '@angular/forms';

export interface Evenement {
  id: string;
  nom: string;
  idAparte: number;
  date: Date;
  nomClient: string;
  statutRapprochementOk: boolean;
  datePlusVieuxReglementNonRapproche: Date;
  totalReverse: number;
  statutEvenement: StatutEvenement;
  montantDu: number;
  detail: string;
  montantTotal: number;
  acompteEnCours: boolean;
}

export interface EvenementDataTable {
  items: Evenement[];
  page: {
    current: number;
    size: number;
  };
  totalCount: number;
}

export interface EvenementFilterForm {
  codeClients: FormControl<string[]>;
  rechercheEvenement: FormControl<string>;
  aRedditionnerUniquement: FormControl<boolean>;
  estArchive: FormControl<boolean>;
  filtreDateDebut: FormControl<string>;
  filtreDateFin: FormControl<string>;
}

export interface EvenementFilter {
  codeClients: string[];
  rechercheEvenement: string;
  aRedditionnerUniquement: boolean;
  estArchive: boolean;
  filtreDateDebut: string;
  filtreDateFin: string;
}

export enum StatutEvenement {
  Acompte = 'Acompte',
  AcompteEnCours = 'AcompteEnCours',
  EnCours = 'EnCours',
  ARedditionner = 'ARedditionner',
  AControler = 'AControler',
  Redditionne = 'Redditionne',
  Reverse = 'Reverse',
}

export interface RedditionStatut {
  idReddition: string;
  statutReddition: string;
}

export interface AcompteStatut {
  idAcompte: string;
  statutAcompte: string;
}

export interface RedditionSuccess {
  idReddition: string;
  montantTotalCommissionsHT: number;
  montantTotalCommissionsTTC: number;
  montantReverseAuClient: number;
}

export interface RedditionCreation {
  idEvenement: string;
  idCompteBancaire: string;
}

export interface AcompteSuccess {
  idAcompte: string;
  montantReverseAuClient: number;
}

export interface AcompteCreation {
  idEvenement: string;
  montantAcompte: number;
  idCompteBancaire: string;
}

export interface AnnulationDialogDataInput {
  evenement: Evenement;
}
