import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FichierSapForm } from './fichier-sap.interface';
import { FichierSapService } from './fichier-sap.service';
import moment from 'moment';
import { AlertService } from '../../../partage/components/alert/alert.service';
import { DateRangePickerComponent } from '../../../partage/components/date-range-picker/date-range-picker.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-fichier-sap.route',
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    DateRangePickerComponent,
  ],
  templateUrl: './fichier-sap.route.component.html',
  styleUrl: './fichier-sap.route.component.scss',
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    provideMomentDateAdapter(),
  ],
})
export class FichierSapRouteComponent {
  fichierSapForm: FormGroup<FichierSapForm>;
  private readonly fichierSapService = inject(FichierSapService);
  private readonly alertService = inject(AlertService);

  constructor() {
    this.fichierSapForm = new FormGroup<FichierSapForm>({
      filtreDateDebut: new FormControl<string>({ value: '', disabled: false }, { nonNullable: true }),
      filtreDateFin: new FormControl<string>({ value: '', disabled: false }, { nonNullable: true }),
    });
  }

  dateFilterIsValid() {
    if (
      !(
        moment(this.fichierSapForm.controls.filtreDateDebut.value).isValid() &&
        moment(this.fichierSapForm.controls.filtreDateFin.value).isValid() &&
        moment(this.fichierSapForm.controls.filtreDateFin.value) >=
          moment(this.fichierSapForm.controls.filtreDateDebut.value)
      )
    ) {
      this.alertService.error('Les dates saisies sont incorrectes.', {
        autoClose: false,
      });
      return false;
    }
    return true;
  }

  telechargerFichier(dateDebut: string, dateFin: string): void {
    if (this.dateFilterIsValid()) {
      this.fichierSapService.telechargerFichier(dateDebut, dateFin);
    }
  }
}
