<div class="body-container">
  <div class="content_header"></div>
  <div class="body-sub-container aic jcc">
    <div class="client_container">
      <div>
        <h4>Selectionnez un client</h4>
        <mat-form-field appearance="outline" class="filter_element">
          <mat-label>Clients</mat-label>

          <input
            type="text"
            matInput
            [ngModel]="clientSelection()"
            (ngModelChange)="clientSelection.set($event)"
            [matAutocomplete]="autoClient"
            (click)="$event.stopPropagation(); clearClient()"
          />
          @if (clientSelection()) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearClient()">
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button matSuffix mat-icon-button>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          }

          <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
            @for (client of filteredClient(); track client) {
              <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    @if (clientSelection()) {
      <div class="header">
        <h4>Règles de commissions globales</h4>
      </div>
      @if (parametragesGlobauxClient(); as parametrages) {
        @if (parametrages.length > 0) {
            @for (comm of parametrages; track comm) {
                <div class="regles_commissions_defaut">
                  <div class="regle_commission">
                    <p class="type_commission">Evénement</p>
                    <div>
                      <div class="lst_regle">
                        <p>Pour chaque place
                          vendue, {{ recupererLibellePourRegle(comm.reglePourPlaces.minimum, comm.reglePourPlaces.pourcentage) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="regle_commission">
                    <p class="type_commission">Formule</p>
                    <div class="lst_regle">
                      <p>Pour chaque formule
                        vendu, {{ recupererLibellePourRegle(comm.reglePourFormules.minimum, comm.reglePourFormules.pourcentage) }}</p>
                    </div>
                  </div>
                </div>
            }
        }
      } @else {
        <p>pas de configuration par défaut</p>
      }
    }
  </div>

  @if (clientSelection()) {
    <div class="body-sub-container aic jcc">
      <h4>Règles de commissions spécifiques</h4>
      <mat-tab-group class="panel_tabs specifiques-tabs" animationDuration="0ms">
        <mat-tab label="Evenements">
          <ng-template matTabContent>
            <div class="tab-content">
              <div class="header">
                <mat-form-field appearance="outline" class="filter_element">
                  <mat-label>Recherche</mat-label>
                  <input
                    #evtInput
                    type="text"
                    matInput
                    [formControl]="filtreParametrageSpecifiqueEvenementControl"
                  />
                  @if (filtreParametrageSpecifiqueEvenementControl.value) {
                    <button matSuffix mat-icon-button aria-label="Clear"
                            (click)="$event.stopPropagation(); filtreParametrageSpecifiqueEvenementControl.setValue('')">
                      <mat-icon>close</mat-icon>
                    </button>
                  }
                </mat-form-field>
                <button type="button" mat-flat-button class="bsbtn btn-primaire" (click)="voirPanel.set(true)">
                  Ajouter une règle spécifique
                </button>
              </div>
            </div>
            <div class="grid_container">
              @if (this.parametragesSpecifiquesEvenement()) {
                <mat-table [dataSource]="this.parametragesSpecifiquesEvenementFiltres()">
                  <caption></caption>
                  <ng-container matColumnDef="nom">
                    <mat-header-cell *matHeaderCellDef>Evènement</mat-header-cell>
                    <mat-cell #tdElement *matCellDef="let row">{{ row.nomEvenement }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="idAparte">
                    <mat-header-cell *matHeaderCellDef>Numéro évenement</mat-header-cell>
                    <mat-cell #tdElement *matCellDef="let row">{{ row.idAparte }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>Date évenement</mat-header-cell>
                    <mat-cell #tdElement *matCellDef="let row">{{ row.dateEvenement | date: 'dd/MM/yyyy HH:mm' }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="regle">
                    <mat-header-cell mat-header-cell *matHeaderCellDef>Règle</mat-header-cell>
                    <mat-cell #tdElement *matCellDef="let row">
                      <div style="display: flex; flex-direction: column; gap: 8px">
                        <span>{{ recupererLibellePourRegle(row.reglePourPlaces.minimum, row.reglePourPlaces.pourcentage) }}</span>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="btnModif">
                    <mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;actions</mat-header-cell>
                    <mat-cell #tdElement *matCellDef="let row">
                      <div class="btnGroupe">
                        @if (row.estVerrouille) {
                          <mat-icon matTooltip="acompte déjà fait avec cette règle">warning</mat-icon>
                        }
                        <button
                          class="icon_btn icon_danger"
                          mat-mini-fab
                          matTooltip="Supprimer le paramétrage spécifique"
                          aria-label="Supprimer le paramétrage spécifique"
                          (click)="openDialog(row)">
                          <mat-icon>delete_forever</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="nomColonneAvecBouton"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: nomColonneAvecBouton"></mat-row>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Aucune donnée trouvée</td>
                  </tr>
                </mat-table>
              }
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Formule">
          <ng-template matTabContent></ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="panel_background" [@showPanel]="voirPanel()" (click)="fermerPanel()" (keypress)="fermerPanel()"></div>
    <div class="panel_container" [@slider]="voirPanel() ? 'open' : 'close'">
      @if (voirPanel()) {
        <app-ajouter-commission-evenement></app-ajouter-commission-evenement>
      }
    </div>
  }
</div>
