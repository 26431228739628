import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Evenement } from './evenements.interface';
import { EvenementsService } from './evenements.service';
import { PanelEvenementDetailComponent } from './panel-evenement-detail/panel-evenement-detail.component';

import 'moment/locale/fr';
import { openSliderAnimation, voirDetailAnimation } from '../../../partage/animations/animations';
import { PDFViewerComponent } from '../../../partage/components/PDF-viewer/pdf-viewer/pdf-viewer.component';
import { DATE_FORMAT_CONFIG } from '../../../partage/providers';
import { FactureService } from '../../facturation/facture.service';
import { PdfActon } from '../global/global-reddition.interface';
import { EvenementFiltreComponent } from './evenement-filtre/evenement-filtre.component';
import { EvenementsListeComponent } from './evenements-liste/evenements-liste.component';

@Component({
  selector: 'app-evenements.route',
  templateUrl: './evenements.route.component.html',
  styleUrl: './evenements.route.component.scss',
  animations: [voirDetailAnimation, openSliderAnimation],
  providers: DATE_FORMAT_CONFIG,
  imports: [
    MatButtonModule,
    MatMomentDateModule,
    MatIconModule,
    MomentDateModule,
    DatePipe,
    PanelEvenementDetailComponent,
    PDFViewerComponent,
    EvenementFiltreComponent,
    EvenementsListeComponent,
  ],
})
export class EvenementsRouteComponent implements OnDestroy {
  ngOnDestroy(): void {
    this.evenementService.evenementSelection.set(undefined);
    this.evenementService.voirDetail.set(false);
  }

  private evenementService = inject(EvenementsService);
  private factureService = inject(FactureService);
  voirDetailEvenement = this.evenementService.voirDetail;
  panelEvenement = this.evenementService.evenementSelection;
  voirPDF = this.factureService.voirPdf;
  aRedditionnerIsChecked = false;
  estArchiveIsChecked = false;
  selection = new SelectionModel<Evenement>(true, []);
  filtre = new FormControl('');
  filteredClient = this.evenementService.filteredClient;
  clientSelection = this.evenementService.clientSelection;
  factureEnCours = this.evenementService.factureEnCours;
  pdfBlob = this.factureService.blob;
  filename = this.factureService.filename;
  statut: string = 'EnAttenteDeFacturation';

  telechargerFactureReddition(idReddition: string, action: PdfActon = PdfActon.telecharger) {
    this.factureService.redditionAction.set({
      id: idReddition,
      action: action,
    });
  }

  telechargerJustificationAcompte(idAcompte: string, action: PdfActon = PdfActon.telecharger) {
    this.factureService.acompteAction.set({
      id: idAcompte,
      action: action,
    });
  }

  telechargerDocumentBlob() {
    const blob = this.pdfBlob()!;
    this.factureService.telechargerDocBlob(blob, this.filename());
  }
  fermerPanelDetails() {
    this.voirPDF.set(false);
    this.voirDetailEvenement.set(false);
    this.panelEvenement.set(undefined);
  }
}
