import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import 'moment/locale/fr';
import { openSliderAnimation, voirDetailAnimation } from '../../../partage/animations/animations';
import { PDFViewerComponent } from '../../../partage/components/PDF-viewer/pdf-viewer/pdf-viewer.component';
import { FactureService } from '../../facturation/facture.service';
import { FormulesFiltreComponent } from './formules-filtre/formules-filtre.component';
import { FormulesListeComponent } from './formules-liste/formules-liste.component';
import { FormulesService } from './formules.service';
import { PanelFormuleDetailComponent } from './panel-formule-detail/panel-formule-detail.component';

@Component({
  selector: 'app-formules.route',
  templateUrl: './formules.route.component.html',
  styleUrl: './formules.route.component.scss',
  animations: [openSliderAnimation, voirDetailAnimation],
  imports: [
    DatePipe,
    MomentDateModule,
    PDFViewerComponent,
    FormulesFiltreComponent,
    FormulesListeComponent,
    PanelFormuleDetailComponent,
  ],
})
export class FormulesRouteComponent {
  private formuleService = inject(FormulesService);
  private factureService = inject(FactureService);

  voirDetailformule = this.formuleService.voirDetail;
  panelformule = this.formuleService.formuleSelection;
  factureEnCours = this.formuleService.factureEnCours;

  voirPDF = this.factureService.voirPdf;
  pdfCourant = this.factureService.downloadedFile;

  aRedditionnerIsChecked = false;
  estArchiveIsChecked = false;

  telechargerDocumentCourant() {
    this.factureService.telechargerDocumentCourant();
  }

  fermerPanelDetails() {
    this.voirPDF.set(false);
    this.voirDetailformule.set(false);
    this.panelformule.set(undefined);
  }
}
