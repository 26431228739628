import {HttpClient} from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {filter, map, startWith, switchMap, tap} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  AjoutParametragesSpecifiquesEvenementRequetePayload,
  Client, Evenement,
  ParametragesGlobauxClientCalculCommission, ParametrageSpecifiqueEvenement,
  ParametragesSpecifiquesCalculCommissionEvenement
} from './commissions.interface.';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  private _httpClient = inject(HttpClient);
  voirPanelAjoutParametrageSpecifiqueEvenement = signal<boolean>(false);

  evenements = signal<Evenement[]>([]);

  clientSelection = signal<Client | undefined>(undefined);
  filterClient = signal('');

  filtreParametrageSpecifiqueEvenement = signal<string>('');

  parametrageSpecifiqueASupprimer = signal<ParametrageSpecifiqueEvenement | undefined>(undefined);
  parametrageSpecifiqueAAjouter = signal<AjoutParametragesSpecifiquesEvenementRequetePayload | undefined>(undefined);

  private clients$ = this._httpClient.get<Client[]>(`${environment.backendUrl}redditions/liste-clients`);
  private clients = toSignal<Client[]>(this.clients$.pipe(startWith([] as Client[])));

  filteredClient = computed(() => {
    const clients = this.clients();
    const filter = this.filterClient();
    if (clients) {
      return clients.filter(c => c.libelleClient.toLowerCase().includes(filter));
    }
    return [] as Client[];
  });

  parametragesSpecifiquesEvenementFiltres = computed(() => {
    const parametrages = this.parametragesSpecifiquesEvenement();
    const filtre = this.filtreParametrageSpecifiqueEvenement();
    if (parametrages) {
      return parametrages.parametrages.filter(c => c.nomEvenement.toLowerCase().includes(filtre));
    }
    return [];
  });

  parametragesGlobauxClient = toSignal(toObservable(this.clientSelection).pipe(
    filter(c => !!c),
    switchMap((client: Client) => {
      const href = `${environment.backendUrl}redditions/parametrage-calcul-commission/${client.codeClient}/global`;

      return this._httpClient.get<ParametragesGlobauxClientCalculCommission>(href).pipe(map(r => r.parametrages));
    })));

  parametragesSpecifiquesEvenement = toSignal(toObservable(this.clientSelection).pipe(
    filter(c => !!c),
    switchMap((client: Client) => {
        const href = `${environment.backendUrl}redditions/parametrage-calcul-commission/${client.codeClient}/specifique/evenement`;

        return this._httpClient.get<ParametragesSpecifiquesCalculCommissionEvenement>(href);
    })));

  private ajouterParametragesSpecifiques  = toSignal(
    toObservable(this.parametrageSpecifiqueAAjouter).pipe(
      filter(Boolean),
      switchMap(parametrage => {
        var endpoint = `${environment.backendUrl}redditions/parametrage-calcul-commission-specifique/evenement`;

        return this._httpClient.post(endpoint, parametrage)
          .pipe(
            tap(_ => {
              this.parametrageSpecifiqueAAjouter.set(undefined);
              this.clientSelection.set({...this.clientSelection()!})
            }));
      }),
    ),
  );

  private supprimerParametrageSpeficique  = toSignal(
    toObservable(this.parametrageSpecifiqueASupprimer).pipe(
      filter(Boolean),
      switchMap(parametrage => {
        var endpoint = `${environment.backendUrl}redditions/parametrage-calcul-commission-specifique/evenement/${parametrage.idEvenement}`;

        return this._httpClient.delete(endpoint)
          .pipe(
            tap(_ => {
              this.parametrageSpecifiqueASupprimer.set(undefined);
              this.clientSelection.set({...this.clientSelection()!})
            }));
      }),
    ),
  );

  recupererLesEvenementsPourParametrage() {
    const href = `${environment.backendUrl}redditions/liste-evenements-parametrage-specifique-calcul-commission/${this.clientSelection()!.codeClient}`;

    return this._httpClient.get<Evenement[]>(href)
      .pipe(tap(r => this.evenements.set(r.sort((a, b) => a.idAparte - b.idAparte))));
  }
}
