<div class="panel_container">
  <div class="detail_evt_fermer">
    <button class="btn_fermer" mat-mini-fab aria-label="fermer panel" (click)="fermerSlider()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2>Sélectionner un ou plusieurs évènements</h2>
  <div class="evenement_select">
    <mat-form-field appearance="outline">
      <mat-label>Evènements</mat-label>
      <mat-select [formControl]="evenementsSelectionnes" multiple>
        @for (evenement of evenements(); track evenement) {
          <mat-option [value]="evenement">
            <div style="display: flex;gap: 10px;align-items: center;">
              <span>{{ evenement.idAparte }} - {{ evenement.nomEvenement }} du {{ evenement.dateEvenement | date: 'dd/MM/yyyy'}}</span>
              @if (evenement.estVerrouille) {
                <mat-chip>
                acompte fait
              </mat-chip>
              }

              @if (evenement.parametrageSpecifique) {
                <mat-chip>
                  déjà paramétré
                </mat-chip>
              }
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  @if (evenementsSelectionnes.value && evenementsSelectionnes.value.length > 0) {
    <h2>Evénement(s) sélectionné(s)</h2>
    <div class="evenement_selection">
      <mat-list role="list">
        @for (evt of evenementsSelectionnes.value; track evt) {
          <mat-list-item role="listitem">
            <div class="evenement_selection_element">{{ evt.idAparte }} - {{ evt.nomEvenement }} du {{ evt.dateEvenement | date: 'dd/MM/yyyy'}}
              @if (evt.parametrageSpecifique) {
                <span class="deja-parametre-flag">déjà un paramétrage spécifique</span>
              }
              <mat-icon matTooltip="supprimer de la sélection" style="cursor: grab" aria-hidden="false" (click)="retirerEvenementDeLaSelection(evt)">clear</mat-icon>
            </div>
          </mat-list-item>
        }
      </mat-list>
    </div>
    <div class="regle_container">
      <form [formGroup]="reglesForm">
        <h2>Sélectionner une règle</h2>
        <div class="regle_card">
          <h4>Pour les places</h4>
          <div class="regle_form_row" formGroupName="reglePourPlaces">
            <mat-form-field class="dialog_form_field" appearance="outline">
              <mat-label>Pourcentage à appliquer</mat-label>
              <input matInput type="number" formControlName="pourcentage" min="0" max="100"/>
              <mat-icon matSuffix>percent</mat-icon>
            </mat-form-field>
            <mat-form-field class="dialog_form_field" appearance="outline">
              <mat-label>Montant à appliquer</mat-label>
              <input matInput type="number" formControlName="minimum" min="0"/>
              <mat-icon matSuffix>euro</mat-icon>
            </mat-form-field>
          </div>
          @if (reglesForm.value.reglePourPlaces?.minimum) {
            <div class="preview">
              {{ reglePourPlacesLibelle() }}
            </div>
          }
        </div>
      </form>
    </div>
  }
  <div class="footer_container">
    <button type="button" mat-flat-button class="bsbtn btn-secondaire" (click)="fermerSlider()">Annuler</button>
    <button type="button" mat-flat-button class="bsbtn btn-primaire" [disabled]="!reglesForm.valid"
            (click)="valider()">Valider
    </button>
  </div>
</div>
